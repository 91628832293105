<template>
  <v-app>
    <app-core-app-bar />
    <app-core-drawer v-if="isAuthenticated" />
    <app-core-view />
    <error-dialog
      :dialog="showErrorDialog"
      title="Failed to Load the Application"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </v-app>
</template>
<script>
  export default {
    name: 'AppPagesIndex',
    components: {
      AppCoreAppBar: () => import('@/views/components/core/AppBar'),
      AppCoreDrawer: () => import('@/views/components/core/Drawer'),
      AppCoreView: () => import('@/views/components/core/View'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return {
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        accessToken: null,
        modelProcessing: false,
      }
    },
    computed: {
      isAuthenticated: function () {
        return this.$msal.isAuthenticated()
      },
    },
    created: function () {
      this.$vuetify.theme.dark = false
      this.$store.dispatch('GET_CLIENT_IP')
        .then(response => {
          if (this.msal.isAuthenticated === true) {
            if (this.$route.name !== 'Dashboard') {
              this.$router.push({
                name: 'Dashboard',
              })
            }
          }
        })
    },
    beforeDestroy: function () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
